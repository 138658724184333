import logo from '../assets/images/logo.png';
import header from '../assets/images/header.jpg';
import informatic from '../assets/images/informatic.jpg';
import favicon from '../assets/images/uni-fav.png';

export const IMAGES = {
	logo,
	header,
	informatic,
	favicon
};
