import { makeAutoObservable } from 'mobx';

class PopupStoreModel {
	isOpen = false;
	constructor() {
		makeAutoObservable(this);
	}
	togglePopup = () => {
		this.isOpen = !this.isOpen;
	};
	closePopup = () => {
		this.isOpen = false;
	};
	openPopup = () => {
		this.isOpen = true;
	};
}

class QrStoreModel {
	qrIsOpen = false;
	qrUrl = null;
	qrValidity = null;
	constructor() {
		makeAutoObservable(this);
	}
	toggleQr = () => {
		this.qrIsOpen = !this.qrIsOpen;
	};
	closeQr = () => {
		this.qrIsOpen = false;
	};
	openQr = () => {
		this.qrIsOpen = true;
	};
	setQrUrl = (value) => {
		this.qrUrl = value;
	};
	setQrValidity = (value) => {
		this.qrValidity = value;
	};
	reset = () => {
		this.qrIsOpen = false;
		this.qrUrl = null;
		this.qrValidity = null;
	};
}

class AdmissionStoreModel {
	admissionState = null;
	constructor() {
		makeAutoObservable(this);
	}
	setAdmissionState = (value) => {
		this.admissionState = value;
	};
	reset = () => {
		this.admissionState = null;
	};
}

class AuthStoreModel {
	authProfile = null;
	constructor() {
		makeAutoObservable(this);
	}
	setAuthProfile = (value) => {
		this.authProfile = value;
	};
	reset = () => {
		this.authProfile = null;
	};
}

class UserStoreModel {
	user = null;
	userStatusCode = null;

	constructor() {
		makeAutoObservable(this);
	}
	setUser = (value) => {
		if (value === null) {
			this.user = null;
			return;
		}
		this.user = value;
	};

	setUserStatusCode = (value) => {
		this.userStatusCode = value;
	};
	getUserStatusCode = () => {
		return this.userStatusCode;
	};
	reset = () => {
		this.user = null;
		this.userStatusCode = null;
	};
}

class GuidesStoreModel {
	allRightMessageType = ''; // 'logined' | 'onboarded | admission | pdf-shared'
	isGuideStepUpdate = false;
	constructor() {
		makeAutoObservable(this);
	}
	setAllRightMessageType = (value) => {
		this.allRightMessageType = value;
	};

	guideStepUpdate = () => {
		this.isGuideStepUpdate = !this.isGuideStepUpdate;
	};
	reset = () => {
		this.allRightMessageType = '';
		this.isGuideStepUpdate = false;
	};
}

class DocumentsStoreModel {
	static default = {
		inbound: {
			documents: null,
			pagesCount: 1,
			currentPage: 1
		},
		outbound: {
			documents: null,
			pagesCount: 1,
			currentPage: 1
		}
	};

	data = DocumentsStoreModel.default;

	constructor() {
		makeAutoObservable(this);
	}

	get allDocuments() {
		return Object.values(this.data).reduce(
			(previousValue, currentValue) =>
				currentValue.documents
					? [ ...previousValue, ...currentValue.documents ]
					: previousValue,
			[]);
	}

	setDocuments = (type, value) => {
		this.data[type].documents = value;
	};

	setDocument = (type, value) => {
		this.data[type].documents = this.data[type].documents.map(d => d.uuid === value.uuid ? value : d);
	};

	setPagesCount = (type, value) => {
		this.data[type].pagesCount = value || 1;
	};

	setCurrentPage = (type, value) => {
		this.data[type].currentPage = value;
	};

	reset = () => {
		this.data = DocumentsStoreModel.default;
	};
}

export const STORES = {
	PopupStore: new PopupStoreModel(),
	QrStore: new QrStoreModel(),
	UserStore: new UserStoreModel(),
	AdmissionStore: new AdmissionStoreModel(),
	AuthStore: new AuthStoreModel(),
	GuidesStore: new GuidesStoreModel(),
	DocumentsStore: new DocumentsStoreModel()
};

export const resetStores = () => {
	Object.values(STORES).forEach(store => store?.reset());
};
