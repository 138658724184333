import { useStores } from 'common-frontend/components/store-provider';
import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../constants/paths';
import { useLocation, useNavigate } from 'react-router-dom';

export const UserPage = observer(() => {
	const { t } = useTranslation();
	const { UserStore } = useStores();
	const { user } = UserStore;
	const location = useLocation();
	const navigate = useNavigate();

	const [ serializedUser, setSerializedUser ] = useState([]);

	const birthDateConcat = useCallback((user) => {
		if (user?.birthDay && user?.birthMonth && user?.birthYear) {
			const day = user.birthDay < 10 ? `0${user.birthDay}` : user.birthDay;
			const month = user.birthMonth < 10 ? `0${user.birthMonth}` : user.birthMonth;
			return `${day}.${month}.${user.birthYear}`;
		}
		return '';
	}, []);

	useEffect(() => {
		if (!user) {
			setSerializedUser([]);

			return;
		}

		const serializedUser = Object.keys(user)
			.filter(
				key => user[key] !== null
				&& user[key] !== undefined
				&& key !== 'id'
				&& key !== 'walletId'
				&& key !== 'birthDay'
				&& key !== 'birthMonth')
			.map(key => {
				let id = key;
				let value = user[key];

				if (id === 'birthYear') {
					id = 'birthDate';
					value = birthDateConcat(user);
				}

				// Temporary workaround until the app validates empty strings
				return ({ id, value: /^\s+$/.test(value) ? value.replaceAll(/\s/g, '⎵') : value });
			});

		setSerializedUser(serializedUser);
	}, [ user, birthDateConcat ]);

	const handleBackClick = () => {
		navigate(location.key === 'default' ? PATHS.home : -1);
	};

	return (
		<main className="main-user">
			<section className={`accordion ${serializedUser.length ? 'active' : ''}`}>
				<Typography variant="h4">{t('user.personal-data')}</Typography>
				<div className="content">
					{!!serializedUser.length && serializedUser.map((u) => (
						<div className="input-row" id={u.id} key={u.id}>
							<Typography>{t(`user.section-data.${u.id}`)}</Typography>
							<input type="text" value={u.value} readOnly={true}/>
						</div>
					))}
				</div>
			</section>
			{!!serializedUser.length &&
				<Button variant="contained" className="mt-24" id="back-button" onClick={handleBackClick}>{t('back')}</Button>}
		</main>
	);
});

